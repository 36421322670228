.hero-container {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust this value as per your requirement */
  padding: 20 2rem; /* Add some space on the sides */
  color: #fff;
  background-size: cover;
  background-position: center;
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/banner.png");
  z-index: 1;
}
